<template>
  <div class="grey lighten-3">
    <v-container>
      <v-row class="mt-4" align="center">
        <v-col v-if="isNormalUser" cols="12" sm="3">
          <v-btn
            elevation="0"
            x-large
            block
            color="orange darken-1"
            dark
            link
            to="/register"
            class="rounded-lg"
          >
            فروشنده شو!
          </v-btn>
        </v-col>
        <v-col cols="12" :sm="isNormalUser ? 9 : 12">
          <search-bar @onSubmit="onSubmit" x-large />
          <!-- <div class="d-flex elevation-4 rounded-lg white align-center">
            <v-text-field
              v-model="search"
              flat
              solo
              label="جستجو..."
              hide-details
              class="rounded-r-lg"
            />
            <v-btn
              elevation="0"
              fab
              class="rounded-l-lg rounded-0"
              color="orange darken-1"
              dark
              @click="onSubmit"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div> -->
        </v-col>
      </v-row>

      <v-row class="mt-4" v-if="loading">
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="(product, i) in 16"
          :key="'skeleton' + i"
        >
          <v-skeleton-loader
            type="card, card-heading"
            class="moholand-shadow"
          />
        </v-col>
      </v-row>

      <v-row v-else class="list-min-height">
        <template v-if="users.length">
          <v-col
            sm="6"
            md="4"
            lg="3"
            cols="12"
            v-for="(item, i) in users"
            :key="i"
          >
            <seller-item :item="item" />
          </v-col>
        </template>
        <v-col
          v-else
          cols="12"
          class="d-flex align-center justify-center"
          style="min-height: 50vh"
        >
          <h3 class="my-auto">کاربری یافت نشد.</h3>
        </v-col>
      </v-row>
      <my-paginate
        v-model="current_page"
        :value="current_page"
        :length="pagesLength"
        @onChange="onPageChanged"
      />
    </v-container>
  </div>
</template>

<script>
import MyPaginate from "../../components/MyPaginate.vue";
import SellerItem from "../../components/Pages/SellerItem.vue";
import SearchBar from "../../components/SearchBar.vue";
import MyAxios from "../../constants/MyAxios";

export default {
  components: { SellerItem, MyPaginate, SearchBar },
  data() {
    return {
      search: this.$route.query?.name || "",
      users: [],
      loading: true,
      pagesLength: 1,
      path: null,
      total: null,
      current_page: +this.$route.query?.page || 1,
    };
  },
  mounted() {
    this.GetData();
  },
  computed: {
    isNormalUser() {
      return (
        !this.$store.state.userData ||
        (this.$store.state.userData &&
          this.$store.state.userData.roleName == "user")
      );
    },
  },
  methods: {
    GetData: function() {
      window.scrollTo({ top: 0 });
      if (!this.loading) this.loading = true;
      MyAxios.get("/users/index", {
        params: {
          with: ["seller"],
          conditions: {
            deleted_at: null,
          },
          search: this.search || undefined,
          page: this.$route.query?.page || 1,
        },
      })
        .then((response) => {
          // this.users = response.data.data.filter((val) => !!val.seller);
          this.users = response.data.data;
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onSubmit(search) {
      this.search = search;
      this.$router.push({
        name: "SellersList",
        query: {
          name: search || undefined,
        },
      });
      this.GetData();
    },
    onPageChanged(page) {
      this.$router.push({
        name: "SellersList",
        query: {
          ...this.$route.query,
          page,
        },
      });
      this.GetData();
    },
  },
};
</script>

<style scoped>
.list-min-height {
  min-height: 70vh;
}
</style>
